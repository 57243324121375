.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.flex_grid{
  display: flex;
  overflow-x: scroll;
}

.card{
  border: 1px solid gray;
  /* flex-basis: 40%; */
  width: 10px;
  height: 10px;
  flex-shrink: 0;
}
#container-fit {
  /* display: inline-block; */
  display: table;
}
.App-header {
  background-color: #eff6f8;
  min-height: 100vh;
  /* display: flex; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.top-content {
  margin: 0 auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  width: 600px;
  height: fit-content;
  
}

body {
  margin: 0;
}

p {
  font-family: 'Lato', sans-serif;
  letter-spacing: 1px;
  font-size: 14px;
  color: #333333;
}

.header {
  position: relative;
  text-align: center;
  /* background-image: url(diya.jpg); */
  background: linear-gradient(60deg, rgba(84, 58, 183, 1) 0%, rgba(0, 172, 193, 1) 100%);
  color: white;
}

.logo {
  width: 50px;
  fill: white;
  padding-right: 15px;
  display: inline-block;
  vertical-align: middle;
}

.inner-header {
  /* height: 65vh; */
  width: 100%;
  margin: 0;
  padding: 0;
}

.flex {
  /*Flexbox for containers*/
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.waves {
  /* position: relative; */
  width: 100%;
  /* height: 15vh; */
  /* margin-bottom: -7px; */
  /*Fix for safari gap*/
  min-height: 75px;
  max-height: 100px;
}

.content {
  position: relative;
  height: 20vh;
  text-align: center;
  background-color: white;
}

/* Animation */

.parallax>use {
  animation: move-forever 25s cubic-bezier(.55, .5, .45, .5) infinite;
}

.parallax>use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}

.parallax>use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}

.parallax>use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}

.parallax>use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}

@keyframes move-forever {
  0% {
      transform: translate3d(-90px, 0, 0);
  }

  100% {
      transform: translate3d(85px, 0, 0);
  }
}

/*Shrinking for mobile*/
@media (max-width: 768px) {
  .waves {
      height: 40px;
      min-height: 90px;
  }

  .content {
      height: 30vh;
  }

  h1 {
      font-size: 24px;
  }
}

.poster {
  background-color: #f8f8f8;
  /* background-image: url(back.jpg); */
  /* padding: 20px; */
  border-radius: 10px;
  max-width: 600px;
  margin: 0 auto;
  /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3); */
}

.template-title {
  padding: 3%;
  padding-bottom: 0%;
  font-size: 200%;
  font-weight: bolder;
  color: #ff9933;
  margin-bottom: 20px;
  font-family: Georgia, 'Times New Roman', Times, serif
}

.template-description {
  /* padding-left : 5%; */
  padding : 5%;
  font-family: 'Times New Roman', Times, serif;
  font-size: 120%;
  font-weight: 900;
}

.diya-img {
  margin: 5%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  max-width: 40%;
  border-radius: 5%;
  border: 5px solid #fff;
}

.profile-pic {
  position: absolute;
  bottom: -2%;
  left: 85%;
  transform: translateX(-50%);
  border-radius: 5%;
  border: 5px solid #fff; 
  /* width: 20%; */
  max-height: 20%;
  object-fit: cover;
}

.user-name {
  position: absolute;
  text-align: center;
  color: black;
  font-size: 100%;
  font-weight: bolder;
  /* top: -50; */
  left: 10%;
  font-family: Georgia, 'Times New Roman', Times, serif;
  /* margin-top: 100px; */
  /* padding-left: 10px; */
}